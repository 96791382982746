<template>
    <el-container>
        <Header pageName="帮助管理"></Header>
        <el-main>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="后台菜单" name="1">
                    <div class="add" style="margin-bottom: 10px">
                        <el-button type="primary" size="small" @click="addBackstageMenu(1, 0, 1)">添加后台菜单</el-button>
                        <el-button type="primary" size="small" @click="saveBackstageMenuSort('', 1, 1)">保存菜单排序</el-button>
                    </div>
                    <el-tabs v-model="case_id" type="card" @tab-click="getBackstageMenu">
                        <el-tab-pane v-for="(item, index) in caseList" :key="index" :label="item.name"
                            :name="item.id"></el-tab-pane>
                    </el-tabs>
                    <div class="menu">
                        <div class="head">
                            <div>菜单名称</div>
                            <div>菜单路径</div>
                            <div>菜单图标</div>
                            <div>操作</div>
                        </div>
                        <div class="menu-list">
                            <No v-if="!backstageMenu.length"></No>
                            <vuedraggable v-model="backstageMenu" v-bind="{
                                animation: 200,
                                disabled: false,
                                ghostClass: 'ghost',
                                scroll: false,
                            }" :group="{ name: 'people', pull: true }">
                                <div class="menu-list-item" v-for="(menu, index) in backstageMenu" :key="index">
                                    <div class="menu-list-parent">
                                        <div class="menu-name">
                                            <i class="el-icon-arrow-right"
                                                :style="{ transform: menu.is_open ? `rotateZ(90deg)` : '' }"
                                                v-if="menu.childs" @click="openBackstageMenu(menu)"></i>
                                            <span v-else></span>
                                            {{ menu.auth_name }}
                                        </div>
                                        <div class="menu-path">{{ menu.auth_key }}</div>
                                        <div class="menu-icon">
                                            <el-image style="background: black; max-width: 30px; max-height: 30px"
                                                :src="menu.auth_icon"></el-image>
                                        </div>
                                        <div class="menu-actions">
                                            <el-button type="text" size="small"
                                                @click="addBackstageMenu(2, index, 1)">添加子菜单</el-button>
                                            <el-button type="text" size="small"
                                                @click="editBackstageMenu(1, menu, 1)">编辑</el-button>
                                            <el-button type="text" size="small"
                                                @click="delBackstageMenu(menu, 1)">删除</el-button>
                                        </div>
                                    </div>
                                    <vuedraggable v-if="menu.is_open" v-model="menu.childs" v-bind="{
                                        animation: 200,
                                        disabled: false,
                                        ghostClass: 'ghost',
                                        scroll: true,
                                    }" :group="{ name: 'child', pull: true }" @start="start">
                                        <div class="menu-list-child" v-for="(childs, y) in menu.childs" :key="y">
                                            <div class="menu-name">
                                                <div>{{ childs.auth_name }}</div>
                                            </div>
                                            <div class="menu-path">{{ childs.auth_key }}</div>
                                            <div class="menu-icon"></div>
                                            <div class="menu-actions">
                                                <el-button type="text" size="small"
                                                    @click="editBackstageMenu(2, childs, 1)">编辑</el-button>
                                                <el-button type="text" size="small"
                                                    @click="delBackstageMenu(childs, 1)">删除</el-button>
                                            </div>
                                        </div>
                                    </vuedraggable>
                                </div>
                            </vuedraggable>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="门店菜单" name="2">
                    <div class="add" style="margin-bottom: 10px">
                        <el-button type="primary" size="small" @click="addBackstageMenu(1, 0, 2)">添加门店菜单</el-button>
                        <el-button type="primary" size="small" @click="saveBackstageMenuSort('', 1, 2)">保存菜单排序</el-button>
                    </div>
                    <div class="menu">
                        <div class="head">
                            <div>菜单名称</div>
                            <div>菜单路径</div>
                            <div>菜单图标</div>
                            <div>操作</div>
                        </div>
                        <div class="menu-list">
                            <No v-if="!storeMenu.length"></No>
                            <vuedraggable v-model="storeMenu" v-bind="{
                                animation: 200,
                                disabled: false,
                                ghostClass: 'ghost',
                                scroll: false,
                            }" :group="{ name: 'people', pull: true }">
                                <div class="menu-list-item" v-for="(menu, index) in storeMenu" :key="index">
                                    <div class="menu-list-parent">
                                        <div class="menu-name">
                                            <i class="el-icon-arrow-right" v-if="menu.childs"
                                                @click="openBackstageMenu(menu)"></i>
                                            <span v-else></span>
                                            {{ menu.auth_name }}
                                        </div>
                                        <div class="menu-path">{{ menu.auth_key }}</div>
                                        <div class="menu-icon">
                                            <el-image style="background: black; max-width: 30px; max-height: 30px"
                                                :src="menu.auth_icon"></el-image>
                                        </div>
                                        <div class="menu-actions">
                                            <el-button type="text" size="small"
                                                @click="addBackstageMenu(2, index, 2)">添加子菜单</el-button>
                                            <el-button type="text" size="small"
                                                @click="editBackstageMenu(1, menu, 2)">编辑</el-button>
                                            <el-button type="text" size="small"
                                                @click="delBackstageMenu(menu, 2)">删除</el-button>
                                        </div>
                                    </div>
                                    <vuedraggable v-if="menu.is_open" v-model="menu.childs" v-bind="{
                                        animation: 200,
                                        disabled: false,
                                        ghostClass: 'ghost',
                                        scroll: true,
                                    }" :group="{ name: 'child', pull: true }" @start="start">
                                        <div class="menu-list-child" v-for="(childs, y) in menu.childs" :key="y">
                                            <div class="menu-name">
                                                <div>{{ childs.auth_name }}</div>
                                            </div>
                                            <div class="menu-path">{{ childs.auth_key }}</div>
                                            <div class="menu-icon"></div>
                                            <div class="menu-actions">
                                                <el-button type="text" size="small"
                                                    @click="editBackstageMenu(2, childs, 2)">编辑</el-button>
                                                <el-button type="text" size="small"
                                                    @click="delBackstageMenu(childs, 2)">删除</el-button>
                                            </div>
                                        </div>
                                    </vuedraggable>
                                </div>
                            </vuedraggable>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </el-main>
        <el-dialog :title="operationType == 1 ? ' 添加/编辑后台菜单' : '添加/编辑门店菜单'" :visible.sync="is_showBackstageMenuAdd"
            width="600px">
            <el-form ref="form" :model="addBackstageForm" :rules="rules" label-width="100px">
                <el-form-item label="菜单名称：" prop="auth_name">
                    <el-input v-model="addBackstageForm.auth_name"></el-input>
                </el-form-item>
                <el-form-item label="菜单标题：" prop="auth_meta" v-if="addBackstageMenuType == 1">
                    <el-input v-model="addBackstageForm.auth_meta"></el-input>
                </el-form-item>
                <el-form-item label="菜单图标：" prop="auth_icon" v-if="addBackstageMenuType == 1">
                    <add-img :path="addBackstageForm.auth_icon" :url="1"
                        @getImg="val => (addBackstageForm.auth_icon = val)"></add-img>
                </el-form-item>
                <el-form-item label="菜单路径：" prop="auth_key">
                    <el-input v-model="addBackstageForm.auth_key"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="is_showBackstageMenuAdd = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmAddBackstageForm">确 定</el-button>
            </span>
        </el-dialog>
    </el-container>
</template>

<script>
import _ from 'lodash';
import vuedraggable from 'vuedraggable';
import Header from './components/header';
import addImg from '@/components/addImg';
export default {
    components: {
        Header,
        vuedraggable,
        addImg,
    },
    data () {
        return {
            type: 2,
            activeName: '1',
            backstageMenu: [],
            storeMenu: [],
            caseList: [],
            case_id: '',
            is_showBackstageMenuAdd: !1,
            addBackstageMenuType: 1, //1、添加一级菜单 2、添加二级菜单
            operationType: 1, // 1、操作后台菜单 2、操作门店菜单
            addBackstageMenuIndex: 0, // 父级菜单下标
            addBackstageForm: {
                auth_name: '',
                auth_key: '',
                auth_icon: '',
                auth_meta: '',
            },
            rules: {
                auth_name: [
                    {
                        required: true,
                        message: '请输入菜单名称',
                        trigger: 'blur',
                    },
                ],
                auth_key: [
                    {
                        required: true,
                        message: '请输入菜单路径',
                        trigger: 'blur',
                    },
                ],
                auth_icon: [
                    {
                        required: true,
                        message: '请添加菜单图标',
                        trigger: 'blur',
                    },
                ],
                auth_meta: [
                    {
                        required: true,
                        message: '请输入菜单标题',
                        trigger: 'blur',
                    },
                ],
            },
        };
    },
    created () {
        this.getCase();
        this.getStoreMenu();
    },
    methods: {
        addBackstageMenu (val, index, operationType) {
            if (val == 1) {
                this.addBackstageForm = {
                    auth_name: '',
                    auth_key: '',
                    auth_icon: '',
                    auth_meta: '',
                };
            } else {
                this.addBackstageMenuIndex = index;
                this.addBackstageForm = {
                    auth_name: '',
                    auth_key: '',
                };
            }
            this.operationType = operationType;
            this.addBackstageMenuType = val;
            this.is_showBackstageMenuAdd = !0;
        },
        editBackstageMenu (type, row, operationType) {
            if (type == 1) {
                this.addBackstageForm = {
                    auth_name: row.auth_name,
                    auth_key: row.auth_key,
                    auth_icon: row.auth_icon,
                    auth_meta: row.auth_meta,
                };
            } else {
                this.addBackstageForm = {
                    auth_name: row.auth_name,
                    auth_key: row.auth_key,
                };
            }
            this.operationType = operationType;
            this.addBackstageForm.auth_id = row.auth_id;
            this.addBackstageMenuType = type;
            this.is_showBackstageMenuAdd = !0;
        },
        //获取行业版本
        getCase () {
            this.$axios.post(this.$api.user.getCase).then(res => {
                if (res.code == 0) {
                    this.case_id = res.result[0].id;
                    this.caseList = res.result;
                    this.getBackstageMenu();
                }
            });
        },
        //获取后台菜单
        getBackstageMenu () {
            this.$axios
                .post(this.$api.admin.backstageMenu, {
                    case_id: this.case_id,
                })
                .then(res => {
                    if (res.code == 200) {
                        let menu = res.result;
                        menu.map(item => {
                            item.is_open = 0;
                        });
                        this.backstageMenu = menu;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //获取门店菜单
        getStoreMenu () {
            this.$axios.post(this.$api.admin.storeMenu).then(res => {
                if (res.code == 0) {
                    let menu = res.result;
                    menu.map(item => {
                        item.is_open = 0;
                    });
                    this.storeMenu = menu;
                }
            });
        },
        //展开后台&门店二级菜单
        openBackstageMenu (row) {
            if (row.is_open) {
                row.is_open = 0;
            } else {
                row.is_open = 1;
            }
        },
        //编辑排序菜单
        saveBackstageMenuSort (id, is_updateMenu, operationType1) {
            let data = {};
            let url = null;
            let operationType = operationType1 ? operationType1 : this.operationType;
            let backstageMenu = operationType == 1 ? this.backstageMenu : this.storeMenu;
            let menuId = [];
            for (let i in backstageMenu) {
                let obj = {};
                obj.id = backstageMenu[i].auth_id;
                if (backstageMenu[i].childs) {
                    obj.child = [];
                    for (let y in backstageMenu[i].childs) {
                        obj.child.push({
                            id: backstageMenu[i].childs[y].auth_id,
                        });
                    }
                }
                menuId.push(obj);
            }
            if (!is_updateMenu) {
                if (this.addBackstageMenuType == 1) {
                    menuId.push({
                        id: id,
                    });
                } else {
                    //判断是否编辑状态
                    if (!this.addBackstageForm.auth_id) {
                        let child = menuId[this.addBackstageMenuIndex].child || [];
                        child.push({ id: id });
                        menuId[this.addBackstageMenuIndex].child = child;
                    }
                }
            }
            if (operationType == 1) {
                data.case_id = this.case_id;
            }
            data.auth_order = menuId;
            url = operationType == 1 ? this.$api.admin.editBackstageMenuSort : this.$api.admin.addStoreMenuSort;
            this.$axios.post(url, data).then(res => {
                if (res.code == 0) {
                    if (!is_updateMenu) {
                        operationType == 1 ? this.getBackstageMenu() : this.getStoreMenu();
                    }
                    this.is_showBackstageMenuAdd = !1;
                    this.$message.success(this.addBackstageForm.auth_id ? '编辑成功' : '保存成功');
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        //删除后台菜单
        delBackstageMenu (row, operationType) {
            this.$confirm('请确认是否删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(operationType == 1 ? this.$api.admin.delBackstageMenu : this.$api.admin.delStoreMenu, {
                        auth_id: row.auth_id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.$message.success('删除成功');
                            operationType == 1 ? this.getBackstageMenu() : this.getStoreMenu();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
        //添加菜单
        comfirmAddBackstageForm () {
            this.$refs.form.validate(valid => {
                if (valid) {
                    let url = null;
                    if (this.operationType == 1) {
                        //后台
                        url = this.addBackstageForm.auth_id ? this.$api.admin.editBackstageMenu : this.$api.admin.addBackstageMenu;
                    } else {
                        //门店
                        url = this.addBackstageForm.auth_id ? this.$api.admin.editStoreMenu : this.$api.admin.addStoreMenu;
                    }
                    this.$axios.post(url, this.addBackstageForm).then(res => {
                        if (res.code == 0) {
                            this.saveBackstageMenuSort(res.result);
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-container {
    flex-direction: column;

    .el-main {
        background: #fff;

        .menu {
            font-size: 14px;
            border: 1px solid #ebeef5;

            .head {
                height: 45px;
                background: #f8f9fa;
                display: flex;
                align-items: center;
                padding: 0 50px;
                justify-content: space-between;

                &>div {
                    flex: 1;
                    text-align: center;
                }
            }

            .menu-list {
                .menu-list-item {
                    .menu-list-parent {
                        padding: 0 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        border-top: 1px solid #ebeef5;
                        justify-content: space-between;
                        cursor: move;

                        &>div {
                            flex: 1;
                            display: flex;
                            justify-content: center;
                        }

                        .menu-name {
                            i {
                                cursor: pointer;
                                transition: all 0.2s linear;
                            }

                            span {
                                width: 14px;
                                height: 14px;
                                display: inline-block;
                            }
                        }
                    }

                    .menu-list-child {
                        padding: 0 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        border-top: 1px solid #ebeef5;
                        justify-content: space-between;
                        cursor: move;

                        &>div {
                            flex: 1;
                            display: flex;
                            justify-content: center;
                        }

                        .menu-name {
                            padding-left: 25px;

                            span {
                                margin-left: 14px;
                                width: 14px;
                                height: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}</style>
